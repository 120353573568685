<template>
  <section>
    <b-card>
      <b-card-title>Meus Seguidores
        <b-button
          variant="relief-warning"
          class="float-right"
          :disabled="loading"
          @click.stop="refill()"
        >
          <span v-if="!loading">Refilar todos</span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </b-card-title>

      <TablePagination
        v-model="items"
        url="/user/services/my_purchases/sn_actions/paginate"
        :per-page="10"
        default-filter-by="profile"
        :fields="fields"
      >
        <template v-slot:name="data">
          <div style="width: 125px">
            <span
              style="word-break: break-all"
              v-html="data.items.name"
            />
          </div>
        </template>

        <template v-slot:profile="data">
          <div style="width: 125px">
            <span
              style="word-break: break-all;"
              v-html="data.items.profile"
            />
          </div>
        </template>

        <template v-slot:id="data">
          #{{ data.items.id }}
        </template>

        <template v-slot:quantity="data">
          {{ data.items.quantity }}
        </template>

        <template v-slot:start_count="data">
          {{ data.items.start_count }}
        </template>

        <template v-slot:remains="data">
          {{ data.items.remains }}
        </template>

        <template v-slot:status="data">
          <b-button
            size="sm"
            :variant="getStatus(data.items.status).color"
          >
            {{ getStatus(data.items.status).message }}
          </b-button>
        </template>

        <template v-slot:value="data">
          <b-button
            size="sm"
            variant="relief-primary"
          >
            {{ data.items.value | currency }}
          </b-button>
        </template>

        <template v-slot:created_at="data">
          <b-button
            size="sm"
            variant="relief-primary"
          >
            {{ data.items.created_at | date }}
          </b-button>
        </template>
      </TablePagination>
    </b-card>
  </section>
</template>

<script>
import {
  BSpinner, BCard, BCardTitle, BButton
} from 'bootstrap-vue'
import moment from 'moment'
import TablePagination from '@/components/TablePagination/Index.vue'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BSpinner,
    BCard,
    BCardTitle,
    BButton,
    TablePagination
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 6)
    },
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  data: () => ({
    loading: false,
    items: [],
    fields: [
      {
        key: 'id',
        label: 'id'
      },
      {
        key: 'status',
        label: 'status'
      },
      {
        key: 'name',
        label: 'Nome',
        sortable: true
      },
      {
        key: 'profile',
        label: 'link',
        sortable: true
      },
      {
        key: 'quantity',
        label: 'Qtd'
      },
      {
        key: 'start_count',
        label: 'Qtd.Inicial'
      },
      {
        key: 'remains',
        label: 'Restante'
      },
      {
        key: 'value',
        label: 'valor',
        sortable: true
      },
      {
        key: 'created_at',
        label: 'data',
        sortable: true
      }
    ]
  }),
  mounted () {
    setTimeout(() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      window.Echo.private(`user.${userData.id}`).listen(
        '.SNActions',
        res => {
          const data = this.items.find(item => item.id === res.id)

          if (data) {
            data.status = res.status
            data.remains = res.remains
            data.start_count = res.start_count
          }
        }
      )
    }, 2000)
  },
  methods: {
    getStatus (status) {
      if (status === 0) {
        return {
          color: 'relief-dark',
          message: 'Em fila'
        }
      }

      if (status === 1) {
        return {
          color: 'relief-primary',
          message: 'Pendente'
        }
      }

      if (status === 2) {
        return {
          color: 'relief-warning',
          message: 'Em progresso'
        }
      }

      if (status === 3) {
        return {
          color: 'relief-success',
          message: 'Finalizado'
        }
      }

      if (status === 4) {
        return {
          color: 'relief-danger',
          message: 'Cancelado'
        }
      }

      if (status === 5) {
        return {
          color: 'relief-warning',
          message: 'Parcial'
        }
      }

      if (status === 6) {
        return {
          color: 'relief-danger',
          message: 'Sem Saldo'
        }
      }
    },
    async refill () {
      this.loading = true
      const url = '/user/services/my_purchases/sn_actions/refill'
      const params = {
        refill_id: this.items.map(item => item.id)
      }

      this.$http.post(url, params)
        .finally(() => {
          this.$swal({
            title: 'O refil foi solicitado com sucesso.',
            html: 'Pedimos que aguarde até 24 horas para cair por completo. <br> <br> Obs: Foi solicitado nos perfis visíveis dessa página. Caso deseje solicitar em outros perfis, mude de página e solicite novamente. <br> <br> Dica: você poderá aumentar a quantidade de perfis exibidos por página, podendo assim refilar até 200 perfis por vez.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })

          this.loading = false
        })
    }
  }
}
</script>
